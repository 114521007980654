* {
 box-sizing: border-box;
 font-family: 'Open Sans', sans-serif;
}

html, body {
  margin: 0;
}

a, a:visited, a:hover {
  color: #0071ce;
}